import { formatDistanceToNowStrict, sub, isAfter } from 'date-fns';
import merge from 'lodash.merge';
import { addParams } from '@buzzfeed/bf-utils/lib/query-string';
import { pickFlexproVariation } from './flexpro';
import { VIDEO } from '../constants/feeds';
import { bfUrl } from '../constants';

const imageOutputParams = {
  'output-format': 'auto',
  'output-quality': 'auto'
};

const generalResizeParams = {
  fill: '307:203;center,top',
  ...imageOutputParams
};

const topicSplashResizeParams = {
  fill: '720:480;center,top',
  ...imageOutputParams
};

const generalResizeParamsSquare = {
  fill: '480:480;center,top',
  ...imageOutputParams
};

export const formatNumberToShorthand = (num, dec = 1) => {
  if (!num) {
    return '0';
  }

  const abbreviations = { billion: 'B', million: 'M', thousand: 'K' };

  if (num >= 1e9) return (num / 1e9).toFixed(dec).replace(/\.0+$/, '') + abbreviations.billion;
  if (num >= 1e6) return (num / 1e6).toFixed(dec).replace(/\.0+$/, '') + abbreviations.million;
  if (num >= 1e3) return (num / 1e3).toFixed(dec).replace(/\.0+$/, '') + abbreviations.thousand;

  return num.toString();
}

// Make quotes and apostrophes "Curly"
const formatQuotes = (str) => {
  if (!str) {
    return '';
  }

  try {
    str = String(str);
  } catch (error) {
    return '';
  }

  return str
    .replace(/\b'/g, '’')
    .replace(/"\b/g, '\u201c')
    .replace(/\b"/g, '\u201d');
};

export const formatTimestamp = createdAt => {
  var createdTime = formatDistanceToNowStrict(new Date(createdAt), {
    addSuffix: true,
  });

  const formattedTime = createdTime
    .replace('hour', 'hr')
    .replace('hours', 'hr')
    .replace('minute', 'min')
    .replace('minutes', 'min')
    .replace('second', 'sec')
    .replace('seconds', 'sec');

  return formattedTime;
};

export const formatCategoryInfo = (label, badge, type, authorInfo) => {
  let formattedInfo = undefined;

  if (badge === 'quiz') {
    return { label: 'Quiz', url: `${bfUrl}/quizzes` };
  }

  if (type === VIDEO) {
    switch (authorInfo?.name) {
      case undefined:
        return { label: 'BuzzFeed Video', url: `${bfUrl}/videos` };
      case 'As/Is':
        return { label: 'As/Is', url: `${bfUrl}/asis/videos` };
      case 'BuzzFeed Unsolved: Supernatural':
        return { label: authorInfo?.name, url: `${bfUrl}/unsolved` };
      case 'BuzzFeed Unsolved: True Crime':
        return { label: authorInfo?.name, url: `${bfUrl}/unsolved` };
      default:
        return {
          label: authorInfo?.name,
          url: `${bfUrl}/${authorInfo?.username.replace(/_/g, '')}`,
        };
    }
  }

  switch (label) {
    case undefined:
    case 'BuzzFeed':
      formattedInfo = { label: 'Buzz', url: `${bfUrl}/tag/buzz` };
      break;
    case 'As/Is':
      formattedInfo = { label: 'Beauty', url: `${bfUrl}/asis` };
      break;
    case 'Goodful':
      formattedInfo = { label: 'Health', url: `${bfUrl}/goodful` };
      break;
    case 'Nifty':
      formattedInfo = { label: 'Home & Living', url: `${bfUrl}/nifty` };
      break;
    case 'Internet Finds':
      formattedInfo = {
        label: 'Internet Finds',
        url: `${bfUrl}/bestoftheinternet`,
      };
      break;
    case 'LGBT':
      formattedInfo = { label: 'LGBTQ', url: `${bfUrl}/lgbtq` };
      break;
    case 'TVAndMovies':
      formattedInfo = {
        label: 'TV & Movies',
        url: `${bfUrl}/tvandmovies`,
      };
      break;
    case 'National':
      formattedInfo = {
        label: 'National',
        url: 'https://www.buzzfeednews.com/section/national',
      };
      break;
    case 'Inequality':
      formattedInfo = {
        label: 'Inequality',
        url: 'https://www.buzzfeednews.com/section/inequality',
      };
      break;
    default:
      formattedInfo = {
        label: label,
        url: label
          ? `${bfUrl}/${label
              .toLowerCase()
              .replace(/&/g, 'and')
              .replace(/ /g, '-')}`
          : '/',
      };
  }

  return formattedInfo;
};

export const getThumbnail = (imageArray, size) => {
  var sizedImage = imageArray.find(image => {
    return image.size === size;
  });

  if (sizedImage) {
    return sizedImage.url;
  } else {
    return imageArray[0].url;
  }
};

export const addImageParams = (url, params = {}) => {
  const imageUrl = new URL(decodeURIComponent(url));
  const { fill = '', ...resizeParams } = generalResizeParams;

  /**
   * To check if the URL contains any cropping parameter (`crop`, `fill`, or `fit`). If it does, we
   * will use `downsize` instead of `fill` for resizing the image to avoid conflicts. Only one
   * cropping parameter can be used in a request. If both are present, only the first one will be
   * used.
   * @see https://github.com/buzzfeed/mono/tree/master/fastly/img.buzzfeed.com#crop-then-resize
   */
  const cropParamKeys = ['crop', 'fill', 'fit'];
  const hasCropParam = cropParamKeys.some((key) => imageUrl.searchParams.has(key));
  if (hasCropParam) {
    const imageFill = imageUrl.searchParams.get('fill');
    if ((imageFill && imageFill !== fill) || !imageFill) {
      imageUrl.searchParams.set('downsize', `${fill.split(':')[0] || '*'}:*`);
    }
  } else {
    imageUrl.searchParams.set('fill', fill);
  }

  const imageParamEntries = [
    ...imageUrl.searchParams.entries(),
    ...Object.entries({ ...resizeParams, ...params }),
  ].sort(([a], [b]) => a.localeCompare(b));

  // Update imageUrl with new params
  imageUrl.search = '';
  imageParamEntries.forEach(([key, value]) => {
    imageUrl.searchParams.set(key, value);
  });

  return imageUrl.toString();
};

export const buildOriginUrl = (uri, originTag = 'web-hf') => {
  if (!uri || uri.indexOf('origin=') > -1) {
    return uri;
  }
  if (originTag) {
    return addParams(uri, { origin: originTag });
  }
  return uri;
};

export const getThemeStyles = (theme) => {
  if (!theme) {
    return '';
  }

  const {
    color1,
    color2,
    textColor,
    darkModeColor1,
    darkModeColor2,
    darkModeTextColor,
  } = theme;

  /**
   * In case of the following scenarios, the value will be set to `initial` so that fallback colors
   * can default colors (periwinkle palette) will be used:
   * - The page does not have any theme data at all.
   * - The page as theme data, but its variable names do not FULLY match expected set (color1, color2,
   *   textColor).
   */
  return `
    :root {
      --themeColor1: ${color1 || 'initial'};
      --themeColor2: ${color2 || 'initial'};
      --themeTextColor: ${textColor || 'initial'};
      --themeDarkModeColor1: ${darkModeColor1 || 'initial'};
      --themeDarkModeColor2: ${darkModeColor2 || 'initial'};
      --themeDarkModeTextColor: ${darkModeTextColor || 'initial'};
    }`
};

export const getSponsorshipStyles = (sponsorship) => {
  if (!sponsorship) {
    return '';
  }

  const assets = sponsorship?.data?.assets || {};
  const highlightColor = sponsorship?.data?.tab?.highlight_color;
  const highlightContrastColor = sponsorship?.data?.tab?.highlight_contrast_color;

  const cursor = assets.cursor
    ? `url('${assets.cursor}'), auto`
    : 'auto';

  const background = assets.background?.type === 'image' && assets.background?.value
    ? `url('${assets.background.value}') no-repeat fixed center / cover`
    : assets.background?.value;

  const tabBackground = sponsorship?.data?.tab?.background?.value && sponsorship?.data?.tab?.background?.type === 'image'
    ? `url('${sponsorship.data.tab.background.value}') no-repeat fixed center / cover`
    : sponsorship?.data?.tab?.background?.value;

  return `
    :root {
      --sponsorshipBackground: ${background || 'initial'};
      --sponsorshipTabBackground: ${tabBackground || 'initial'}, ${background || 'initial'};
      --sponsorshipCursor: ${cursor || 'auto'};
      --sponsorshipLogoColor: ${assets.logo_color || 'initial'};
      --sponsorshipHighlightColor: ${highlightColor || 'initial'};
      --sponsorshipHighlightContrastColor: ${highlightContrastColor || 'initial'};
    }`;
};

export const normalizePostContent = (content, { originTag, zoneName = '', index } = {}) => {
  const post = content?.post || {};
  const postOverrides = content?.post_overrides || {};
  const description = postOverrides?.description || post?.description || '';
  const objectType = 'post_promo';
  let trackingData = {
    ...content?.trackingData,
  };
  let flexproVariation = {};

  // Special case of the first item in homepage trending splash (zone name: 'splash_trending') - do not use flex pro
  if (zoneName === 'splash_trending' && index === 0) {
    /*
      * The title used should be in this order:
      *  The 'Short Headline' promotion value from the homepage promotion, if it exists
      *  Post override title, if it exists
      *  BPage promotion title (seo title), if it exists
      *  otherwise do default logic
      */
    if (post?.socialpromotions?.bpage?.[0]?.title || postOverrides?.title) {
      postOverrides.title = postOverrides?.title || post?.socialpromotions?.bpage?.[0]?.title;
    }

    if (post.socialpromotions?.homepage?.length > 0) {
      const extraFieldsStr = post.socialpromotions?.homepage?.[0].extra_fields;
      try {
        const extraFieldsObj = JSON.parse(extraFieldsStr);
        postOverrides.title = extraFieldsObj?.short_headline ? extraFieldsObj?.short_headline : postOverrides?.title;
      } catch (error) {
        console.error(error);
      }
    }
  } else {
    flexproVariation = pickFlexproVariation(post);
    trackingData = flexproVariation?.is_flexpro ? {
      data_source_algorithm: [flexproVariation.flexpro_source_algorithm],
      data_source_algorithm_version: [flexproVariation.flexpro_source_algorithm_version],
    } : {};
  }

  /**
   * If there are no flexpro experiments active, then allow post overrides if they exist. Otherwise,
   * a flexpro variation will be chosen as post data and tracking will be updated accordingly..
   */
  const postData = merge({}, post, flexproVariation?.is_flexpro ? flexproVariation : postOverrides);

  /**
   * enrichment data
   * @todo
   * Error handling. Consider sending error event (datadog? sentry?) if a required enrichment is not valid from the
   * API response.
   */
  const comments = content?.comments || {};
  const contentReactions = content?.content_reactions || {};
  const pageviews = content?.pageviews || {};
  const resizeParamsStandard = zoneName !== 'trending_top' ? generalResizeParams : topicSplashResizeParams;
  const laserTags = postData?.laser_tags || {};

  /**
   * Handle thumbnail image data
   */
  const dblbigImage = postData.images?.dblbig;
  const squareImage = postData.images?.square || dblbigImage;
  const dblbigAlt = postData.images?.dblbig_alt_text || '';
  const squareAlt = postData.images?.square_alt_text || dblbigAlt;
  const thumbnail = {
    standard: {
      alt: dblbigAlt,
      url: dblbigImage ? addImageParams(dblbigImage, resizeParamsStandard) : '',
    },
    square: {
      alt: squareAlt,
      url: squareImage ? addImageParams(squareImage, generalResizeParamsSquare) : '',
    },
  };

  const categoryInfo = formatCategoryInfo(
    postData.classification?.section,
    postData.badges?.[0],
    undefined,
    postData.authors?.[0],
  );

  const hideReactions = content?.post?.flags?.comments_enabled !== 1 || categoryInfo?.label === 'Shopping';

  // is the item create date less than 12 hours ago
  const published = isAfter(
    new Date(postData.published * 1000),  // converts unix timestamp from seconds to milliseconds
    sub(Date.now(), {
      hours: 12,
    })
  );

  const formattedPublishedAt = published
    ? formatTimestamp(postData.published * 1000)
    : null;

  // Make quotes and apostrophes "Curly"
  const formattedItemTitle = formatQuotes(postData.title);

  if (comments.count) {
    comments.countFormatted = formatNumberToShorthand(comments.count);
  }

  if (pageviews.count) {
    pageviews.countFormatted = formatNumberToShorthand(pageviews.count);
  }

  const badgeNames = postData?.badges?.map((badge) => badge.name.toLowerCase()) || [];
  const isPopular = badgeNames.includes('viral');

  return {
    category: categoryInfo,
    comments,
    contentReactions,
    description,
    id: postData.id,
    isPopular,
    isSponsored: false,
    laserTags,
    pageviews,
    objectType,
    hideReactions,
    thumbnail,
    timestamp: formattedPublishedAt,
    title: formattedItemTitle,
    trackingData,
    url: buildOriginUrl(postData.canonical_url, originTag),
  };
};

export const normalizeArticleCarouselContent = (content = {}, { originTag = '' } = {}) => {
  if (!content?.posts?.length) {
    return {
      // To protect in case `content` is not an object
      ...(typeof content === 'object' ? content : {}),
      posts: [],
    };
  }

  const posts = content.posts.map((postData) => {
    const thumbnail = {
      standard: {
        url: addImageParams(postData.images.dblbig, generalResizeParams),
        alt: postData.images.dblbig_alt_text,
      },
      square: {
        url: addImageParams(
          (postData.images?.square || postData.images.dblbig),
          generalResizeParamsSquare
        ),
        alt: postData.images?.square_alt_text || postData.images.dblbig_alt_text,
      },
    };

    // Make quotes and apostrophes "Curly"
    const formattedItemTitle = formatQuotes(postData.title);

    return {
      id: postData.id,
      thumbnail,
      title: formattedItemTitle,
      url: buildOriginUrl(postData.canonical_url, originTag),
      object_type: 'article',
    };
  });

  return {
    ...content,
    posts,
  };
};

/**
 * @todo
 * Error handling. Consider sending error event (datadog? sentry?) if a required enrichment is not
 * valid from the API response.
 */
export const normalizeDiscussionContent = (content) => {
  if (!content || typeof content !== 'object') {
    return { comments: {} };
  }

  const { comments = {} } = content;
  if (comments?.count) {
    comments.countFormatted = formatNumberToShorthand(comments.count);
  }

  return {
    ...content,
    comments: comments || {},
  };
};

const normalizeTopCommentContent = (content, options) => {
  return {
    items: [{
      linkTitle: 'Mattel Released Even More Barbie Dolls Inspired By The Movie, So Here Are The Side-By-Sides',
      linkUrl: 'https://www.buzzfeed.com/laurengarafano/mattel-released-even-more-barbie-dolls-inspired-by-the-movie',
      image: 'https://img.buzzfeed.com/buzzfeed-stage/static/2023-09/20/21/campaign_images/32d83f983a5f/17-teeny-tiny-almost-unnoticeable-details-from-fi-3-6357-1695243785-1_big.jpg?fill=266%3A176%3Bcenter%2Ctop&output-format=auto&output-quality=auto&ops=600_398',
      text: 'I’m all for Ken’s story being told….Ryan Gosling just isn’t the right person I think Jacob Elordi would have been better',
      userName: 'user123456',
      userLink: 'https://stage.buzzfeed.com/sam_cleal',
      userImg: 'https://img.buzzfeed.com/buzzfeed-static/static/user_images/CKf3LZgvu_large.jpg?output-format=jpg&crop=480%3A480%3B19%2C15?output-format=jpeg&output-quality=85&downsize=30:*'
    }]
  };
};

const normalizeInOutContent = (content, options) => {
  return {
    items:[
      {
        title: 'What’s IN This Week',
        linkTitle: 'Mattel Released Even More Barbie Dolls Inspired By The Movie, So Here Are The Side-By-Sides',
        linkUrl: 'https://www.buzzfeed.com/laurengarafano/mattel-released-even-more-barbie-dolls-inspired-by-the-movie',
        image: 'https://img.buzzfeed.com/buzzfeed-stage/static/2023-09/20/21/campaign_images/32d83f983a5f/17-teeny-tiny-almost-unnoticeable-details-from-fi-3-6357-1695243785-1_big.jpg?fill=266%3A176%3Bcenter%2Ctop&output-format=auto&output-quality=auto&ops=600_398',
        list: [
          'Girl Dinner',
          'TikTok',
          'food'
        ],
      },
      {
        title: 'What’s IN This Week',
        linkTitle: 'Mattel Released Even More Barbie Dolls Inspired By The Movie, So Here Are The Side-By-Sides',
        linkUrl: 'https://www.buzzfeed.com/laurengarafano/mattel-released-even-more-barbie-dolls-inspired-by-the-movie',
        image: 'https://img.buzzfeed.com/buzzfeed-stage/static/2022-02/17/19/campaign_images/af9c2a9adebe/19-cleaning-hacks-for-people-who-are-super-lazy-b-2-2032-1645124424-6_big.jpg?fill=266%3A176%3Bcenter%2Ctop&output-format=auto&output-quality=auto&ops=600_398',
        list: [
          'Girl Dinner',
        ],
      }
    ],
  };
};

/**
 * @todo
 * Add "automatic" type of promo bar
 */
const normalizePromoBar = (content) => {
  return {
    ... content?.manual,
    type: content?.type,
  };
}

/**
 * Normalize BFP content data.
 * The function primarily removes unused properties to help reduce the size of the initial data used
 * to hydrate the client.
 * @param {object} content - The BFP content object from the Feed API response.
 * @returns {object} The normalized BFP content object.
 */
export const normalizeBFPContent = (content = {}) => {
  if (!content.bfp) {
    return content;
  }

  const normalizedContent = merge({}, content);

  if (normalizedContent.bfp.renders?.oo_web) {
    normalizedContent.bfp.renders = {
      oo_web: normalizedContent.bfp.renders.oo_web,
    }
  } else {
    // If `bfp.renders.oo_web` is falsy, remove the entire `bfp.renders` array
    delete normalizedContent.bfp.renders;
  }

  delete normalizedContent.bfp.clients;
  delete normalizedContent.bfp.render_kit_version;

  return normalizedContent;
}

export const normalizePackageContent = (content, { package_feed }) => {
  const items = package_feed?.items?.map((feedItem) => {
    const { content, feed_source } = feedItem;
    const normalizedItem = {
      ...feedItem,
      content: { ...normalizePostContent(content), hideReactions: true }
    };

    if (feed_source?.id) {
      const dataSourceName = normalizedItem.content?.trackingData?.data_source_name || [];
      dataSourceName.push('feed_api');

      const dataSourceAlgorithm = normalizedItem?.content?.trackingData?.data_source_algorithm || [];
      dataSourceAlgorithm.push(feed_source?.id.toString());

      const dataSourceAlgorithmVersion = normalizedItem?.content?.trackingData?.data_source_algorithm_version || [];
      dataSourceAlgorithmVersion.push('v1');

      normalizedItem.content.trackingData = merge({}, {
        data_source_name: dataSourceName,
        data_source_algorithm: dataSourceAlgorithm,
        data_source_algorithm_version: dataSourceAlgorithmVersion,
      });
    }

    const { id, title, url, thumbnail } = normalizedItem?.content;
    if (id && title?.length && url?.length && Object.keys(thumbnail).length) {
      return normalizedItem;
    } else {
      return null;
    }
  }) || [];

  return {
    items: items.filter(item => item !== null) || [],
    ...content
  };
};

export const normalizeContentObjectMap = {
  article_carousel: normalizeArticleCarouselContent,
  bfp_content: normalizeBFPContent,
  discussion_question: normalizeDiscussionContent,
  in_out: normalizeInOutContent,
  package: normalizePackageContent,
  post_promo: normalizePostContent,
  promo_bar: normalizePromoBar,
  top_comment: normalizeTopCommentContent,
};

export const normalizeContentObject = ({ content, object_type, ...options }) => {
  let normalizedContent = {};

  if (content && typeof content === 'object') {
    normalizedContent = { ...content };

    if (normalizedContent?.images?.standard) {
      normalizedContent.images.standard = addImageParams(normalizedContent.images.standard);
    }
    if (normalizedContent?.images?.mobile) {
      normalizedContent.images.mobile = addImageParams(normalizedContent.images.mobile);
    }

    if (typeof normalizeContentObjectMap[object_type] === 'function') {
      return normalizeContentObjectMap[object_type](normalizedContent, options);
    }
  }

  return normalizedContent;
};

export const normalizeSponsorship = (sponsorships) => {
  if (!sponsorships || !sponsorships.length || !sponsorships[0].data) {
    return null;
  }

  const {
    data,
    sponsor,
  } = sponsorships[0];

  const type = data.type || 'module';

  const sponsorship = {
    type,
    sponsor,
    data: {},
  };

  sponsorship.sponsor = merge({}, sponsorship.sponsor, data.sponsor_overrides || {});

  switch (type) {
    case 'takeover':
      sponsorship.data.assets = data.assets;
      sponsorship.data.name = data.name;

      if (data.sponsored_tab?.enabled) {
        sponsorship.data.tab = data.sponsored_tab;
      }

      break;
    case 'module':
      if (data?.colors) {
        sponsorship.data.colors = data.colors;
      }
      break;
    default:
      return null;
  }

  return sponsorship;
};

export const normalizeZone = (zone = {}, { originTag = '', debug = '' } = {}) => {
  const { display_name = '', items = [], metadata = {}, name = '', next = null } = zone;
  const normalizedItems = items.map(({ id, content, feed_source, object_type, package_feed, sponsorships }, index) => {
    const normalizedItem = {
      content: normalizeContentObject({ content, debug, feed_source, object_type, originTag, package_feed, zoneName: name, index }),
      id, // objectID
      object_type
    };

    if (sponsorships) {
      normalizedItem.sponsorship = normalizeSponsorship(sponsorships);
    }

    if (feed_source?.id) {
      const dataSourceName = normalizedItem.content?.trackingData?.data_source_name || [];
      dataSourceName.push('feed_api');

      const dataSourceAlgorithm = normalizedItem.content?.trackingData?.data_source_algorithm || [];
      dataSourceAlgorithm.push(feed_source?.id.toString());

      const dataSourceAlgorithmVersion = normalizedItem.content?.trackingData?.data_source_algorithm_version || [];
      dataSourceAlgorithmVersion.push('v1');

      normalizedItem.content.trackingData = merge({}, {
        data_source_name: dataSourceName,
        data_source_algorithm: dataSourceAlgorithm,
        data_source_algorithm_version: dataSourceAlgorithmVersion,
      });
    }

    return normalizedItem;
  });

  return {
    display_name,
    items: normalizedItems,
    metadata,
    name,
    next,
  };
};

export const normalizeZones = (zones = [], { originTag = '', debug = '' } = {}) => {
  return zones.reduce((accZones, zone) => {
    if (zone.name) {
      accZones[zone.name] = normalizeZone(zone, { originTag, debug });
    }
    return accZones;
  }, {});
};

export const filterZonesByPrefix = (zones, prefix = '') => {
  if (!zones || !Object.keys(zones).length || !prefix?.length) {
    return {};
  }
  return Object.keys(zones).reduce((filteredZones, name) => {
    if (name.startsWith(prefix)) {
      filteredZones[name] = zones[name];
    }
    return filteredZones;
  }, {});
};

export const getFactOfTheDay = (items, previewTimestamp) => {
  // if viewing as future preview, pull out that date
  const date = previewTimestamp ? new Date(previewTimestamp) : new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  if (
    !items?.length ||
    !items[0].content?.facts
  ) return null;

  return items[0].content.facts[month][day];
};

/**
 * The sum of all replies from community questions active on a page at the time of the request.
 * @param {array} zones - The zones from the feed API that make up the page.
 * @returns {number} The total discussion count.
 */
export const getDiscussionCount = async (zones = []) => {
  let totalCount = 0;

  if (!zones || !zones.length) {
    return totalCount;
  }

  const communityObjectTypes = ['discussion_question'];

  for (const { items = [] } of zones) {
    for (const { content = {}, object_type = '' } of items) {
      const commentsCount = content.comments?.count;

      // These types should be enriched with comments API data
      if (communityObjectTypes.includes(object_type) && commentsCount) {
        totalCount += commentsCount ?? 0
      }
    };
  };

  return totalCount;
};

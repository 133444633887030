import React from 'react';
import PropTypes from 'prop-types';
import FeedCard from '../FeedCard';
import SplashCard from '../FeedCard/SplashCard';
import LinkWithTracking from '../LinkWithTracking';
import ImagePlaceholder from '../../static/img/image-placeholder.svg';
import { ChevronRight } from '../../icons/ChevronRight';
import styles from './package.module.scss';

const CtaButton = ({ cta, trackingData }) => {
  if (!cta?.text?.length) {
    return null;
  }
  if (!!cta?.link?.length) {
    return (
      <LinkWithTracking
        href={cta.link}
        className={styles.cta}
        commonTrackingData={trackingData}
      >
        {cta.text}
        <ChevronRight />
      </LinkWithTracking>
    );
  }
  return (
    <span className={styles.cta}>
      {cta.text}
    </span>
  );
}

export const Package = ({ item = {}, className, trackingData = {} }) => {
  const { content, id: contentObjectId } = item || {};
  const { items = [], cta, title, trackingData: algorithmTrackingData } = content || {};

  if ((content?.items?.length ?? 0) < 3) {
    return null;
  }

  const splash = !!items?.length && items[0];
  const trendingItems = !!items?.length && items?.slice(1, 3);
  const topicItems = !!items?.length && items?.slice(3, 6);

  const subunitTrackingData = {
    subunit_type: 'package',
    subunit_name: `package|${contentObjectId}`,
  };

  const sharedTrackingData = {
    ...trackingData,
    ...subunitTrackingData,
  };

  const getPackagesTrackingData = (type, index) => {
    switch (type) {
      case 'splash':
        return {
          ...sharedTrackingData,
          ...splash?.content?.trackingData, // algorithm bit for individual item
          position_in_subunit: 0,
        };
      case 'trending':
        return {
          ...sharedTrackingData,
          ...trendingItems[index]?.content?.trackingData,
          item_type: 'card',
          item_name: trendingItems[index]?.content?.id,
          position_in_subunit: 1 + index, // splash is at position 0
          target_content_type: 'buzz',
          target_content_id: trendingItems[index]?.content?.id,
        };
      case 'topic':
        return {
          ...sharedTrackingData,
          ...topicItems[index]?.content?.trackingData,
          item_type: 'card',
          item_name: topicItems[index]?.content?.id,
          position_in_subunit: 3 + index, // splash and trending are at positions 0 and 1 and 2
          target_content_type: 'buzz',
          target_content_id: topicItems[index]?.content?.id,
        };
      default: // or 'cta'
        return {
          ...sharedTrackingData,
          ...algorithmTrackingData,
          item_type: 'text',
          item_name: cta?.text,
          target_content_type: 'url',
          target_content_url: cta?.link,
          target_content_id: cta?.link,
        };
    }
  };

  return (
    <section className={`${styles.package} ${className ? className : ''}`}>
      <div className={styles.content}>
        {!!title?.length && (<h2 className={styles.title}>{title}</h2>)}
        <div className={styles.postsWrap}>
          <SplashCard
            className='packageSplashCard'
            isTrackable={true}
            item={splash}
            imageRatio='3/2'
            imageRatioTablet='1/1'
            imageRatioDesktop='1/1'
            trackingData={getPackagesTrackingData('splash')}
          />
          <div className={styles.trendingPosts}>
            {!!trendingItems?.length && trendingItems.map((trendingItem, idx) => (
              <FeedCard
                isTrackable={true}
                key={trendingItem.content.id}
                className={styles.trendingPost}
                trackingData={getPackagesTrackingData('trending', idx)}
              >
                <FeedCard.Link
                  isTrackable={true}
                  url={trendingItem.content.url}
                  trackingData={getPackagesTrackingData('trending', idx)}
                >
                  <FeedCard.Thumbnail
                    alt={trendingItem.content.thumbnail?.standard?.alt}
                    className={styles.thumbnail}
                    lazyLoadThumbnail={true}
                    thumbnail={trendingItem.content.thumbnail?.standard?.url || ImagePlaceholder}
                    ratio="3/2"
                  />
                  <h3>{trendingItem.content.title}</h3>
                </FeedCard.Link>
              </FeedCard>
            ))}
          </div>
          <div className={styles.topicPosts}>
            {!!topicItems?.length && topicItems.map((topicItem, idx) => (
              <FeedCard
                isTrackable={true}
                key={topicItem.content.id}
                className={styles.topicPost}
                trackingData={getPackagesTrackingData('topic', idx)}
              >
                {idx === 0 && (
                  <div className="xs-hide lg-block">
                    <FeedCard.Link
                      isTrackable={true}
                      url={topicItem.content.url}
                      trackingData={getPackagesTrackingData('topic', idx)}
                    >
                    <FeedCard.Thumbnail
                      alt={topicItem.content.thumbnail?.standard?.alt}
                      className={styles.thumbnail}
                      lazyLoadThumbnail={true}
                      thumbnail={topicItem.content.thumbnail?.standard?.url || ImagePlaceholder}
                      ratio="3/2"
                    />
                    </FeedCard.Link>
                  </div>

                )}
                {topicItem.content.category &&
                  <span className={styles.topicTitle}>{topicItem.content.category.label}</span>
                }
                <FeedCard.Link
                  isTrackable={true}
                  url={topicItem.content.url}
                  trackingData={getPackagesTrackingData('topic', idx)}
                >
                  <h3>{topicItem.content.title}</h3>
                </FeedCard.Link>
              </FeedCard>
            ))}
          </div>
        </div>
        <CtaButton
          cta={content?.cta}
          trackingData={getPackagesTrackingData('cta')} />
      </div>
    </section>
  );
};

Package.propTypes = {
  item: PropTypes.shape({
    content: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object),
      cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
      }),
      title: PropTypes.string,
      trackingData: PropTypes.object,
    }),
  }),
  className: PropTypes.string,
  trackingData: PropTypes.object,
};

export default Package;

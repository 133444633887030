import React from 'react';
import FeedCard from '../index';
import styles from './splashCard.module.scss';

const getThumbnailType = (ratio = '') => {
  const ratioParams = ratio.split('/');
  return ratioParams?.length > 1 && ratioParams[0] === ratioParams[1] ? 'square' : 'standard';
};

export const SplashCard = ({
  item,
  index = 0,
  imageRatio = '3/2',
  imageRatioTablet = '3/2',
  imageRatioDesktop = '3/2',
  showTagLabel = false,
  isPrimary = true,
  className = '',
  isTrackable= false,
  trackingData = {},
}) => {

  const { content, id: contentObjectId, object_type: objectType } = item || {};

  if (!content) return null;

  const { trackingData: algorithmTrackingData } = content;

  const subunitTrackingData = {
    subunit_type: 'component',
    subunit_name: `${objectType}|${contentObjectId}`,
    position_in_subunit: 0
  };

  const sharedTrackingData = {
    ...subunitTrackingData,
    ...algorithmTrackingData,
    item_type: 'card',
    item_name: content?.id,
    target_content_id: content?.id,
    target_content_type: 'buzz',
    ...trackingData // important to remain last, and take precendence
  };

  const cardClassName = [
    styles.splashCard,
    isPrimary ? styles.primaryPost : styles.secondaryPost,
    index > 0 ? styles.hasIndex : '',
    styles[className]
  ].join(' ');

  const thumbnailType = getThumbnailType(imageRatio);
  const thumbnailTabletType = getThumbnailType(imageRatioTablet);
  const thumbnailDesktopType = getThumbnailType(imageRatioDesktop);

  return (
    <FeedCard
      className={cardClassName}
      isTrackable={isTrackable}
      trackingData={sharedTrackingData}
    >
      <FeedCard.Link
        url={content.url}
        isTrackable={isTrackable}
        className={styles.linkWrap}
        trackingData={sharedTrackingData}
      >
        <figure className={styles.thumbnail}>
          <picture>
            {thumbnailType !== thumbnailTabletType && <source
              srcSet={content.thumbnail?.[thumbnailTabletType]?.url}
              media='(min-width: 40rem)'
            />}
            {thumbnailTabletType !== thumbnailDesktopType && <source
              srcSet={content.thumbnail?.[thumbnailDesktopType]?.url}
              media='(min-width: 64rem)'
            />}
            <FeedCard.Thumbnail
              thumbnail={content.thumbnail?.[thumbnailType]?.url}
              alt={content.thumbnail?.[thumbnailType]?.alt}
              ratio={imageRatio}
              ratioTablet={imageRatioTablet}
              ratioDesktop={imageRatioDesktop}
            />
          </picture>
        </figure>
        <div className={styles.textWrapper}>
          {showTagLabel && (
            <FeedCard.InfoLabel
              className={styles.tagLabel}
              label="Popular"
            />
          )}
          {index > 0 && <FeedCard.IndexLabel className={styles.index}>{index}</FeedCard.IndexLabel>}
          <h3 className={styles.heading}>{content.title}</h3>
        </div>
      </FeedCard.Link>
      {!content?.hideReactions && <div className={styles.statsWrapper}>
        <FeedCard.Reactions
          className={styles.reactions}
          contentId={Number(content.id)} // buzz id
          contentType="buzz"
          data={content.contentReactions}
          isTrackable={isTrackable}
          trackingData={sharedTrackingData}
        />
        <FeedCard.Stats
          className={styles.stats}
          commentsClassName={styles.statsLink}
          commentsCount={content?.comments?.count > 4 ? content?.comments?.countFormatted : null}
          commentLink={content.url}
          isTrackable={isTrackable}
          trackingData={sharedTrackingData}
          viewsCount={content?.pageviews?.countFormatted}
        />
      </div>}
    </FeedCard>
  );
};

export default SplashCard;
